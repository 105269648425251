<template>
  <div class="payment-auth-list">
    <div v-if="paymentAuthList.length > 0">
      <v-list>
        <v-subheader>Le tue carte</v-subheader>
        <v-list-item-group
          v-model="selectedRow"
          mandatory
          @change="selectPaymentAuth"
        >
          <v-list-item
            v-for="(paymentAuth, index1) in paymentAuthList"
            :key="paymentAuth.paymenthAuthId"
          >
            <v-list-item-avatar rounded="0" class="payment-radio">
              <v-icon v-if="selectedRow === index1">mdi-radiobox-marked</v-icon>
              <v-icon v-else>mdi-radiobox-blank</v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar rounded="0" class="card-img">
              <img
                :src="getCreditCardImage(paymentAuth.creditCard)"
                alt="Immagine carta di credito"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ paymentAuth.creditCard | ccard }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Scadenza: {{ paymentAuth.expireDate | ccexp }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                icon
                @click.prevent.stop="
                  removePaymentAuth(paymentAuth.userPaymentAuthId)
                "
                aria-label="Rimuovi questa carta"
                title="Rimuovi questa carta"
              >
                <v-icon color="grey lighten-1">$delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="new-card d-flex align-center mt-2">
        <v-btn
          href="#"
          depressed
          color="primary"
          @click="addNewCard()"
          small
          v-if="paymentAuthList.length > 0 && paymentAuthList.length < 4"
        >
          + Aggiungi una nuova carta
        </v-btn>
      </div>
    </div>
    <div>
      <v-alert type="warning" dense outlined>
        Spuntando "Memorizza Carta", la carta che inserirai per il pagamento
        verrà salvata per i tuoi prossimi acquisti.
      </v-alert>
    </div>
  </div>
</template>
<script>
import PaymentTypeService from "~/service/paymentTypeService";

export default {
  name: "PaymentAuthList",
  props: { paymentTypeId: { type: Number, required: true } },
  data() {
    return {
      paymentAuthList: [],
      selectedPaymentAuth: null,
      selectedRow: null,
      rememberNewCard: false
    };
  },
  methods: {
    addNewCard() {
      this.$emit("addNewCard");
    },
    async loadAvailablePaymentAuth() {
      //call to api
      this.paymentAuthList = await PaymentTypeService.getPaymentsAuth(
        this.paymentTypeId
      );

      if (this.paymentAuthList.length > 0) {
        for (let i = 0; i < this.paymentAuthList.length; i++) {
          if (this.paymentAuthList[i].defaultAuth === true) {
            this.selectedRow = i;
            this.selectPaymentAuth();
            break;
          }
        }
      }
    },
    getCreditCardImage(number) {
      if (number) {
        var re = {
          electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+(\**)\d+$/,
          maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+(\**)\d+$/,
          dankort: /^(5019)\d+(\**)\d+$/,
          interpayment: /^(636)\d+(\**)\d+$/,
          unionpay: /^(62|88)\d+(\**)\d+$/,
          visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
          mastercard: /^5[1-5][0-9]{14}$/,
          amex: /^3[47][0-9]{13}$/,
          diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
          discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
          jcb: /^(?:2131|1800|35\d{3})\d{11}$/
        };
        var substr = number.replace(/\*/g, "1");
        for (var key in re) {
          if (re[key].test(substr)) {
            return "/credit-card/" + key + "-curved-64px.png";
          }
        }
        return "/credit-card/visa-curved-64px.png";
      }
      return "";
    },
    selectPaymentAuth() {
      if (this.selectedRow >= 0) {
        let paymentAuth = this.paymentAuthList[this.selectedRow];
        this.$emit("selectPaymentAuth", paymentAuth.userPaymentAuthId);
      }
      // else {
      //   this.$emit("selectPaymentAuth", null);
      // }
    },
    async removePaymentAuth(paymentAuthId) {
      await PaymentTypeService.deletePaymentAuth(paymentAuthId);
      this.$emit("selectPaymentAuth", null);
      this.loadAvailablePaymentAuth();
    }
  },
  mounted() {
    this.loadAvailablePaymentAuth();
  }
};
</script>
